import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Grid,
  CssBaseline,
  Typography,
  Container,
  withStyles,
  Button,
} from "@material-ui/core";
import { firebase } from "./../Firebase";
import Snackbar from "./../Components/Snackbar";
import Loader from "./../Components/Loader";
import jwt_decode from "jwt-decode";
import NotFound from "./404";

const styles = (theme) => ({
  appbar: theme.mixins.toolbar,
  logo: {
    textAlign: "center",
    width: "50%",
  },
  inputField: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  loginButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontWeight: "bold",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
    fontSize: theme.spacing(6),
  },
  subHeader: {
    fontWeight: "800",
    fontSize: theme.spacing(3),
  },
  container: {
    marginTop: theme.spacing(5),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: { marginTop: theme.spacing(15) },
    marginBottom: theme.spacing(6),
    minHeight: (window.innerHeight * 43.7) / 100,
  },
});

class Nomination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      userId: "",
      snackbarMessage: "",
      snackbarSeverity: "",
      snackbarOpen: false,
      isLoading: true,
      isLogin: false,
      campaigner: [],
      proposers: [],
      seconder: [],
      post: "",
    };
  }

  componentDidMount() {
    const profileComponent = this;

    if (localStorage.getItem("token")) {
      const decoded = jwt_decode(localStorage.getItem("token"));

      firebase
        .firestore()
        .collection("users")
        .where("email", "==", decoded.email)
        .onSnapshot(
          (querySnapshot) => {
            const profileData = [];
            querySnapshot.forEach((doc) => profileData.push(doc.data()));

            // If the profile data exists, set the user info and isAdmin status
            if (profileData.length > 0) {
              profileComponent.setState({ isAdmin: profileData[0].isAdmin });
            }
          },
          (error) => {
            console.error("Error fetching profile data:", error);
            this.setState({ isLoading: false });
          }
        );
    }

    // In componentDidMount, modify the auth state change handler:
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ userEmail: user.email, isLogin: true }, () => {
          firebase
            .firestore()
            .collection("users")
            .where("email", "==", this.state.userEmail)
            .get()
            .then(
              (userSnapshot) => {
                const userDoc = userSnapshot.docs[0];
                const userId = userDoc?.id;

                if (userId) {
                  const userData = userDoc.data();
                  this.setState({
                    userId,
                    post: userData.post || "",
                  });
                  console.log("User ID: ", userId);
                  this.fetchUserRoles(userId);
                }
              },
              (error) => {
                console.error("Error fetching user by email:", error);
                this.setState({ isLoading: false });
              }
            );
        });
      } else {
        this.setState({ isLoading: false, isLogin: false });
      }
    });
  }

  checkTeamSizeLimits = (userId, role, post) => {
    return new Promise((resolve, reject) => {
      // Get the user document to check current counts
      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            reject("User document not found");
            return;
          }

          const userData = doc.data();
          const proposersCount = userData.proposersCount || 0;
          const seconderCount = userData.seconderCount || 0;
          const campaignerCount = userData.campaignerCount || 0;

          // Define limits based on post type
          let limits = {
            proposersLimit: 1,
            seconderLimit: 2,
            campaignerLimit: 10,
          };

          // Special case for Candidate for President, Students’ Gymkhana
          if (post === "Candidate for President, Students’ Gymkhana") {
            limits = {
              proposersLimit: 2,
              seconderLimit: 5,
              campaignerLimit: 60,
            };
          }

          // Check if adding this person would exceed the limit
          let canAdd = true;
          let message = "";

          if (role === "proposers" && proposersCount >= limits.proposersLimit) {
            canAdd = false;
            message = `You can only have ${limits.proposersLimit} proposers for this position.`;
          } else if (
            role === "seconder" &&
            seconderCount >= limits.seconderLimit
          ) {
            canAdd = false;
            message = `You can only have ${limits.seconderLimit} seconders for this position.`;
          } else if (
            role === "campaigner" &&
            campaignerCount >= limits.campaignerLimit
          ) {
            canAdd = false;
            message = `You can only have ${limits.campaignerLimit} campaigners for this position.`;
          }

          resolve({ canAdd, message, limits });
        })
        .catch((error) => {
          console.error("Error checking team size limits:", error);
          reject(error);
        });
    });
  };

  handleAccept = (role, email, userId) => {
    // Get the post type from user data
    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const userData = doc.data();
          const post = userData.post || "";

          // Check if the user can add more team members of this role
          this.checkTeamSizeLimits(userId, role, post)
            .then(({ canAdd, message, limits }) => {
              if (!canAdd) {
                // // Automatically reject if limit reached
                // this.handleReject(role, email, userId);
                this.setState({
                  snackbarOpen: true,
                  snackbarMessage: message,
                  snackbarSeverity: "error",
                });
                return;
              }

              // Continue with acceptance if within limits
              let counterField;
              if (role === "proposers") {
                counterField = "proposersCount";
              } else if (role === "seconder") {
                counterField = "seconderCount";
              } else if (role === "campaigner") {
                counterField = "campaignerCount";
              }

              // Update user's status
              firebase
                .firestore()
                .collection("users")
                .where("email", "==", email)
                .get()
                .then((snapshot) => {
                  if (!snapshot.empty) {
                    snapshot.docs[0].ref.update({ status: "Accepted" });
                  }
                });

              // Update subcollection document status and increment counter
              firebase
                .firestore()
                .collection("users")
                .doc(userId)
                .collection(role)
                .where("email", "==", email)
                .get()
                .then((snapshot) => {
                  if (!snapshot.empty) {
                    snapshot.docs[0].ref
                      .update({ status: "Accepted" })
                      .then(() => {
                        // Increment the counter field
                        firebase
                          .firestore()
                          .collection("users")
                          .doc(userId)
                          .update({
                            [counterField]:
                              firebase.firestore.FieldValue.increment(1),
                          })
                          .then(() => {
                            console.log(
                              `${counterField} incremented successfully`
                            );

                            // Update UI state
                            this.setState((prevState) => ({
                              [role]: prevState[role].map((item) =>
                                item.email === email
                                  ? { ...item, status: "Accepted" }
                                  : item
                              ),
                              snackbarOpen: true,
                              snackbarMessage: `${role} accepted successfully!`,
                              snackbarSeverity: "success",
                            }));
                          })
                          .catch((error) => {
                            console.error(
                              `Error incrementing ${counterField}:`,
                              error
                            );
                            this.setState({
                              snackbarOpen: true,
                              snackbarMessage: `Error updating counts.`,
                              snackbarSeverity: "error",
                            });
                          });
                      });
                  }
                });
            })
            .catch((error) => {
              console.error("Error in team size validation:", error);
              this.setState({
                snackbarOpen: true,
                snackbarMessage: "Error validating team size limits.",
                snackbarSeverity: "error",
              });
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        this.setState({
          snackbarOpen: true,
          snackbarMessage: "Error fetching user data.",
          snackbarSeverity: "error",
        });
      });
  };

  handleReject = (role, email, userId) => {
    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .collection(role)
      .where("email", "==", email)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          const doc = snapshot.docs[0];
          const rejectedData = {
            ...doc.data(),
            status: "Rejected",
            role: role, // Add role field
          };

          // Add to Rejected sub-collection
          firebase
            .firestore()
            .collection("users")
            .doc(userId)
            .collection("Rejected")
            .add(rejectedData)
            .then(() => {
              // Delete from the original sub-collection
              doc.ref
                .delete()
                .then(() => {
                  this.setState((prevState) => ({
                    [role]: prevState[role].filter(
                      (item) => item.email !== email
                    ),
                    snackbarOpen: true,
                    snackbarMessage: `${role} rejected successfully and moved to Rejected collection!`,
                    snackbarSeverity: "success",
                  }));
                })
                .catch((deleteError) => {
                  console.error(
                    "Error deleting the rejected document:",
                    deleteError
                  );
                  this.setState({
                    snackbarOpen: true,
                    snackbarMessage: `Failed to remove rejected ${role} from original collection.`,
                    snackbarSeverity: "error",
                  });
                });
            })
            .catch((addError) => {
              console.error("Error adding to Rejected collection:", addError);
              this.setState({
                snackbarOpen: true,
                snackbarMessage: `Failed to add rejected ${role} to Rejected collection.`,
                snackbarSeverity: "error",
              });
            });
        }
      })
      .catch((error) => {
        console.error("Error rejecting the role:", error);
        this.setState({
          snackbarOpen: true,
          snackbarMessage: `Failed to reject ${role}.`,
          snackbarSeverity: "error",
        });
      });
  };

  fetchUserRoles = (userId) => {
    const profileComponent = this;

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .collection("campaigner")
      .get()
      .then((snapshot) => {
        const campaigner = [];
        snapshot.forEach((doc) => {
          campaigner.push({
            ...doc.data(),
            status: doc.data().status || "Pending",
          });
        });
        profileComponent.setState({ campaigner });
      });

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .collection("proposers")
      .get()
      .then((snapshot) => {
        const proposers = [];
        snapshot.forEach((doc) => {
          proposers.push({
            ...doc.data(),
            status: doc.data().status || "Pending",
          });
        });
        profileComponent.setState({ proposers });
      });

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .collection("seconder")
      .get()
      .then((snapshot) => {
        const seconder = [];
        snapshot.forEach((doc) => {
          seconder.push({
            ...doc.data(),
            status: doc.data().status || "Pending",
          });
        });
        profileComponent.setState({ seconder, isLoading: false });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <CssBaseline />
        <div className={classes.appbar}></div>

        {this.state.snackbarOpen && (
          <Snackbar
            open={this.state.snackbarOpen}
            close={() => this.setState({ snackbarOpen: false })}
            message={this.state.snackbarMessage}
            severity={this.state.snackbarSeverity}
          />
        )}

        {this.state.isLoading ? (
          <Loader />
        ) : this.state.isLogin ? (
          <Container maxWidth="md" className={classes.container}>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              className={classes.heading}
            >
              My Team
            </Typography>
            <Typography
              variant="p"
              align="center"
              gutterBottom
              className={classes.paragraph}
            >
              {this.state.post ===
              "Candidate for President, Students' Gymkhana" ? (
                <>
                  Up to 51 Campaigners (Including Campaign Manager) are allowed
                  for the post of President, Students' Gymkhana.
                  <br />
                  {/* 2 Proposers & 5 Seconders are required for the post of
                  President, Students' Gymkhana. */}
                </>
              ) : (
                <>
                  1 Proposer & 2 Seconders are required for the post of
                  Senators.
                  <br />
                  Up to 10 Campaigners are allowed for the post of Senators.
                  <br />
                </>
              )}
            </Typography>

            <Typography
              variant="p"
              align="center"
              gutterBottom
              className={classes.paragraph}
            >
              <br />
              FOR CANDIDATE
              <br />
              PLEASE CLICK ACCEPT BUTTON ONLY ONCE. (NO DOUBLE CLICK OR MULTIPLE
              CLICK)
              <br />
            </Typography>
            <Typography
              variant="h5"
              align="center"
              className={classes.subHeader}
            >
              -
            </Typography>
            <Typography
              variant="h5"
              align="center"
              className={classes.subHeader}
            >
              Proposers
            </Typography>
            <List component="nav">
              {this.state.proposers.length > 0 ? (
                this.state.proposers.map((proposer, idx) =>
                  proposer.emailStatus === "Verified" ? (
                    <ListItem button key={idx}>
                      <ListItemText
                        primary={proposer.name}
                        secondary={
                          <>
                            Roll: {proposer.roll} <br />
                            {proposer.email} <br />
                            {proposer.mobileNumber}
                          </>
                        }
                      />
                      <Grid container spacing={1}>
                        {proposer.status === "Accepted" ? (
                          <Typography
                            variant="subtitle1"
                            color="primary"
                            style={{ marginLeft: "auto" }}
                          >
                            Accepted
                          </Typography>
                        ) : (
                          <div style={{ marginLeft: "auto" }}>
                            <Grid item>
                              <Button
                                style={{
                                  color: "black",
                                  backgroundColor: "#00E76C",
                                  borderRadius: "2px",
                                  marginRight: "5px",
                                }}
                                onClick={() =>
                                  this.handleAccept(
                                    "proposers",
                                    proposer.email,
                                    this.state.userId
                                  )
                                }
                              >
                                Accept
                              </Button>
                              <Button
                                style={{
                                  color: "white",
                                  backgroundColor: "#910E00",
                                  borderRadius: "2px",
                                }}
                                onClick={() =>
                                  this.handleReject(
                                    "proposers",
                                    proposer.email,
                                    this.state.userId
                                  )
                                }
                              >
                                Reject
                              </Button>
                            </Grid>
                          </div>
                        )}
                      </Grid>
                    </ListItem>
                  ) : null
                )
              ) : (
                <Typography variant="subtitle1" align="center">
                  No proposers available
                </Typography>
              )}
            </List>

            <Typography
              variant="h5"
              align="center"
              className={classes.subHeader}
            >
              Seconders
            </Typography>
            <List component="nav">
              {this.state.seconder.length > 0 ? (
                this.state.seconder.map((seconder, idx) =>
                  seconder.emailStatus === "Verified" ? (
                    <ListItem button key={idx}>
                      <ListItemText
                        primary={seconder.name}
                        secondary={
                          <>
                            Roll: {seconder.roll} <br />
                            {seconder.email} <br />
                            {seconder.mobileNumber}
                          </>
                        }
                      />
                      <Grid container spacing={1}>
                        {seconder.status === "Accepted" ? (
                          <Typography
                            variant="subtitle1"
                            color="primary"
                            style={{ marginLeft: "auto" }}
                          >
                            Accepted
                          </Typography>
                        ) : (
                          <div style={{ marginLeft: "auto" }}>
                            <Grid item>
                              <Button
                                style={{
                                  color: "black",
                                  backgroundColor: "#00E76C",
                                  borderRadius: "2px",
                                  marginRight: "5px",
                                }}
                                onClick={() =>
                                  this.handleAccept(
                                    "seconder",
                                    seconder.email,
                                    this.state.userId
                                  )
                                }
                              >
                                Accept
                              </Button>
                              <Button
                                style={{
                                  color: "white",
                                  backgroundColor: "#910E00",
                                  borderRadius: "2px",
                                }}
                                onClick={() =>
                                  this.handleReject(
                                    "seconder",
                                    seconder.email,
                                    this.state.userId
                                  )
                                }
                              >
                                Reject
                              </Button>
                            </Grid>
                          </div>
                        )}
                      </Grid>
                    </ListItem>
                  ) : null
                )
              ) : (
                <Typography variant="subtitle1" align="center">
                  No Seconders available
                </Typography>
              )}
            </List>

            <Typography
              variant="h5"
              align="center"
              className={classes.subHeader}
            >
              Campaigners
            </Typography>
            <List component="nav">
              {this.state.campaigner.length > 0 ? (
                this.state.campaigner.map((campaigner, idx) =>
                  campaigner.emailStatus === "Verified" ? (
                    <ListItem button key={idx}>
                      <ListItemText
                        primary={campaigner.name}
                        secondary={
                          <>
                            Roll: {campaigner.roll} <br />
                            {campaigner.email} <br />
                            {campaigner.mobileNumber}
                          </>
                        }
                      />
                      <Grid container spacing={1}>
                        {campaigner.status === "Accepted" ? (
                          <Typography
                            variant="subtitle1"
                            color="primary"
                            style={{ marginLeft: "auto" }}
                          >
                            Accepted
                          </Typography>
                        ) : (
                          <div style={{ marginLeft: "auto" }}>
                            <Grid item>
                              <Button
                                style={{
                                  color: "black",
                                  backgroundColor: "#00E76C",
                                  borderRadius: "2px",
                                  marginRight: "5px",
                                }}
                                onClick={() =>
                                  this.handleAccept(
                                    "campaigner",
                                    campaigner.email,
                                    this.state.userId
                                  )
                                }
                              >
                                Accept
                              </Button>
                              <Button
                                style={{
                                  color: "white",
                                  backgroundColor: "#910E00",
                                  borderRadius: "2px",
                                }}
                                onClick={() =>
                                  this.handleReject(
                                    "campaigner",
                                    campaigner.email,
                                    this.state.userId
                                  )
                                }
                              >
                                Reject
                              </Button>
                            </Grid>
                          </div>
                        )}
                      </Grid>
                    </ListItem>
                  ) : null
                )
              ) : (
                <Typography variant="subtitle1" align="center">
                  No Campaigners available
                </Typography>
              )}
            </List>
          </Container>
        ) : (
          <NotFound />
        )}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Nomination);
